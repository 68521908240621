module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.14.0_gatsby@5.13.0_babel-eslint@10.1.0_eslint@7.32.0__encoding@0.1.1_obolcwmn7okn7zgtp427b4mbqm/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"test.Hikari-Co","short_name":"test.Hikari-Co","start_url":"/","background_color":"#121212","theme_color":"#121212","display":"standalone","icon":"src/images/star.svg","cache_busting_mode":"none","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-gtag@5.14.0_gatsby@5.13.0_babel-eslint@10.1.0_eslint@7.32.0__encoding@0._eeetduxcc3wydkp2xcj3h73wxe/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-2KQ7QBRKTV"],"gtagConfig":{}},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-remark-images@7.14.0_gatsby-plugin-sharp@5.14.0_gatsby@5.13.0_babel-eslint@10.1.0_esli_crogn2tiilwifkwfmmoq5rx22i/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-nprogress@5.14.0_gatsby@5.13.0_babel-eslint@10.1.0_eslint@7.32.0__encoding@0.1._4yohcxzn4c5qnomiskuira4rxe/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"var(--link)","showSpinner":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-theme-ui@0.15.7_@emotion+react@11.14.0_@types+react@19.0.12_react@18.3.1__@mdx-_uyxpppxcmuepw2e3dtl7jd7xda/node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.0_babel-eslint@10.1.0_eslint@7.32.0__encoding@0.1.13_react-dom@18.3.1_react@18.3._d54d4qzvsbenfg6giqvnoefujm/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
